<template>
  <v-card v-if="!fetchingData && data" class="my-2">
    <v-card-text>
      <h2 class="mb-2">Gebuchtes Event</h2>
      <strong>{{ $t("title") }}:</strong> {{ data.eventTitle }}<br />
      <strong>{{ $t("date") }}:</strong>
      {{ this.formatDateNoTime(data.eventDate) }}<br />
      <strong>{{ $t("startTime") }}:</strong> {{ data.eventStartTime }}<br />
      <strong>{{ $t("amount") }}:</strong> {{ data.amount }}<br />
      <strong>{{ $t("bookingDate") }}:</strong>
      {{ this.formatDate(data.created) }}
    </v-card-text>
  </v-card>
</template>

<script>
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
export default {
  name: "LeadBookedDates",
  props: {
    uuid: {
      type: String,
      required: false,
      default: "leads",
    },
    id: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      data: null,
      fetchingData: false,
    };
  },

  created: async function () {
    this.fetchingData = true;
    let response = await this.getRequest(`leads/${this.id}/bookedDates`);
    if (response.status === 200) {
      const allDates = response.data.data;
      // find the one with leads_id === this.id and put it in data
      this.data = allDates.find((date) => date.leads_id === this.id);
    }
    this.fetchingData = false;
  },

  mixins: [manageApi, helpers],
};
</script>
