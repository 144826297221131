<template>
  <v-card :loading="loading" class="mx-auto">
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-card-title
      class="mitfitDark white--text caption font-weight-bold text-uppercase"
      >{{ $t("latestLeads") }}</v-card-title
    >

    <v-card-text class="mt-4">
      <table class="default-table" v-if="data" style="width: 100%">
        <thead>
          <tr>
            <th>{{ $t("lastActivity") }}</th>
            <th>{{ $t("participant") }}</th>
            <th>{{ $t("campaign") }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in data" :key="item.id">
            <td>
              {{ formatDate(item.created) }}
            </td>
            <td>{{ item.firstName }} {{ item.lastName }}</td>
            <td>{{ item.campaignTitle }}</td>
            <td>
              <v-btn
                fab
                dark
                x-small
                depressed
                color="blue"
                @click="watchItem(item)"
                class="mx-1"
              >
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <p v-else>{{ $t("noData") }}</p>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="1500px"
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-btn icon dark @click="dialog = false"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <v-toolbar-title>Lead</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <!-- Lead der "alten" Version  -->
          <LeadDetailOld
            v-if="leadData.version === 1"
            ref="lead_detail"
            :id="leadId"
            :leadData="leadData"
            :key="componentKey"
          ></LeadDetailOld>

          <!-- Lead der "neuen" Version  -->
          <LeadDetail
            v-else-if="leadData.version === 2"
            ref="lead_detail"
            :id="leadId"
            :leadData="leadData"
            :campaigns_id="leadCampaign.id"
          ></LeadDetail>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import manageApi from "@/mixins/manageApi";
import LeadDetailOld from "@/components/lead/old/LeadDetailOld";
import LeadDetail from "@/components/lead/manage/LeadDetail";
export default {
  name: "LastLeads",
  components: {
    LeadDetail,
    LeadDetailOld,
  },

  data() {
    return {
      hasPermission: true,
      currentCompanyId: this.$store.getters["auth/current_sid"],
      data: [],
      loading: false,
      dialog: false,
      componentKey: 0,
      leadData: [],
      leadCampaign: null,
      leadId: null,
    };
  },

  props: [],

  created: function () {
    this.$store.watch(
      () => this.$store.getters["auth/current_sid"],
      (value) => {
        this.currentCompanyId = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    watchItem(obj) {
      this.leadId = obj.id;
      this.leadCampaign = obj.campaign;

      //finde the real data in array
      //vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war.
      this.data.forEach((item) => {
        if (item.id === this.leadId) {
          this.leadData = item; // the data of that item
        }
      });

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/

      /* this.$router.push({
        name: "campaigns",
      }); */

      /* this.leadId = id;
      //finde the real data in array
      //vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war.
      this.data.forEach((item) => {
        if (item.id === this.leadId) {
          this.leadData = item; // the data of that item
        }
      });

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/ */
    },

    async getData() {
      this.loading = true;
      let response = await this.getRequest(
        `companies/${this.currentCompanyId}/leads?limit=5`
      );
      this.loading = false;

      if (response.status === 200) {
        this.data = response.data.data.leads.slice(0, 5);
      }
    },

    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },

    formatDate(date) {
      date = date.replace(/-/g, "/"); // Safari Issue. https://www.evernote.com/shard/s74/nl/14459636/a6d4cd8e-312e-452b-9393-0a7057285e6b?title=Safari%20Date%20Issue

      const dt = new Date(date);
      const d = this.addZero(dt.getDate()); //Month from 0 to 11
      const m = this.addZero(dt.getMonth() + 1); //Month from 0 to 11
      const y = dt.getFullYear();
      const h = this.addZero(dt.getHours());
      const i = this.addZero(dt.getMinutes());
      return d + "." + m + "." + y + " " + h + ":" + i + " Uhr";
    },
  },

  computed: {
    //
  },

  mixins: [manageApi],
};
</script>
