<template>
  <div>
    <v-card v-if="!fetchinData">
      <v-toolbar dense dark color="dark">
        <v-toolbar-title v-if="hasMitfitPay !== '1' && hasMitfitPay !== 1">
          {{ $t("leadProcessingStatus") }}
        </v-toolbar-title>
        <!-- PAY -->
        <v-toolbar-title v-else>
          <span v-if="!hasMitfitMember">mitfitShop</span>
          <span v-else>mitfitMEMBER</span>
        </v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col class="col-12">
            <table v-if="campaign" class="default-table" style="width: 100%">
              <tbody>
                <tr>
                  <td class="font-weight-bold">{{ $t("campaign") }}</td>
                  <td>
                    {{ campaign.title }}
                    <v-chip
                      v-if="leadType === 'payContract'"
                      class="ma-2"
                      color="teal"
                      text-color="white"
                    >
                      <v-icon left>mdi-alpha-m</v-icon>
                      MEMBER
                    </v-chip>

                    <v-chip
                      v-else-if="leadType === 'payProduct'"
                      class="ma-2"
                      color="teal"
                      text-color="white"
                    >
                      <v-icon left>mdi-cart</v-icon>
                      SHOP
                    </v-chip>
                  </td>
                </tr>

                <tr v-if="leadData.gameSolution">
                  <td class="font-weight-bold">Lösung des Nutzers</td>
                  <td>{{ leadData.gameSolution }}</td>
                </tr>

                <tr>
                  <td class="font-weight-bold">
                    {{ this.$t("adSource") }}
                  </td>
                  <td v-if="leadData.adSource && leadData.adSource !== 'null'">
                    {{ leadData.adSource }}
                  </td>
                  <td v-else>-</td>
                </tr>

                <!-- MEMBERS -->
                <tr v-if="hasMitfitMember === '1' || hasMitfitMember === 1">
                  <td colspan="2">
                    <LeadActivityMemberSection
                      :data="leadData"
                      :campaign="campaign"
                      @orderTerminated="$emit('orderTerminated')"
                    />
                  </td>
                </tr>

                <!-- SHOPS/SALES -->
                <tr v-if="context === 'shop'">
                  <td colspan="2">
                    <LeadActivityShopSection
                      :data="leadData"
                      :campaignName="leadData.campaignName"
                      :campaigns_id="leadData.campaigns_id"
                      @orderTerminated="$emit('orderTerminated')"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>

          <v-col v-if="leadType === 'regular'" class="col-12">
            <h3>{{ $t("status") }}</h3>
            <StateToggles
              :data="leadData"
              :pay="hasMitfitPay"
              @emitted="emitted"
            />
          </v-col>

          <v-col class="col-12" v-if="leadData.isScheduled === '1'">
            <LeadAppointment
              :isScheduledDate="leadData.isScheduledDate"
              :leadId="leadData.id"
              @dateChange="leadData.isScheduledDate = $event"
            />
          </v-col>

          <v-col class="col-12">
            <h3>{{ $t("notes") }}</h3>
            <ActivityNotes
              :leadId="leadData.id"
              :key="noteComponentKey"
              @emitted="emitted"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import helpers from "@/mixins/helpers"; // import mixin (for api requests)
import StateToggles from "@/components/lead/manage/StateToggles";
import ActivityNotes from "@/components/lead/manage/ActivityNotes";
import LeadAppointment from "@/components/lead/manage/LeadAppointment";
import LeadActivityMemberSection from "@/components/lead/manage/LeadActivityMemberSection";
import LeadActivityShopSection from "@/components/lead/manage/LeadActivityShopSection";
export default {
  name: "LeadActivity",

  components: {
    StateToggles,
    ActivityNotes,
    LeadAppointment,
    LeadActivityMemberSection,
    LeadActivityShopSection,
  },

  props: {
    leadData: {
      type: Object,
      required: true,
    },
    campaign: {
      type: Object,
      required: true,
    },
    context: {
      type: String,
      required: false,
      default: "leads",
    },
  },

  data() {
    return {
      fetchinOrderData: false,
      leadType: this.leadData.type, // lead type (regular, payContract, payProduct (neu))
      hasMitfitPay: null,
      hasMitfitMember: null,
      hasMitfitShop: null,
      hasOrdered: null,
      orderId: null,
      fetchinData: false, // should be true when fetching data
      progress: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      textFieldProps: {
        prependIcon: "mdi-calendar-month",
      },

      noteComponentKey: 0,
      hasFeedbackResults: false,
      feedbackResults: null,
    };
  },

  created: function () {
    // mitfitPAY
    if (this.campaign) {
      this.hasMitfitPay = this.campaign.hasMitfitPay;
      this.hasMitfitMember = this.campaign.hasMitfitMember;
      this.hasMitfitShop = this.campaign.hasMitfitShop;
      if (
        this.campaign.hasMitfitMember === "1" ||
        this.campaign.hasMitfitPay === "1" ||
        this.campaign.hasMitfitShop === "1"
      ) {
        this.hasMitfitPay = "1";
      }
    }
  },

  methods: {
    async getFeedbackResults() {
      // GET /feedbackSets/{feedbackSetId}/results/{campaignId}/{leadId}
      /* let response = await this.postRequest(
        `feedbackSets/sendReminder`
      ); */
    },

    emitted(value) {
      /* kommt von ActivityNotes. via emit gebe ich das event zurück, da ich mangels daten den array nicht mutieren kann.
        durch das emit (2 ebenen) starte ich den fetch erneut */

      if (value === "noteAdded") {
        this.$emit("emitted", "noteAdded");
      }
      this.noteComponentKey += 1; // reRender notes component
    },
  },

  computed: {
    dateTimeValue: {
      get() {
        /* 
        We got: 2021-09-28 15:50:00
        We need: Tue Sep 28 2021 15:50:00 GMT+0200 (Mitteleuropäische Sommerzeit) 
        */

        const value = this.leadData.games_data_gamer_isScheduledDate;

        if (!value || value === "") {
          return null;
        }
        const dateTimeValue = new Date(value);
        return dateTimeValue;
      },

      set: function () {
        return this.leadData.games_data_gamer_isScheduledDate;
      },
    },
  },

  mixins: [manageApi, helpers],
};
</script>

<style>
ul.itemslist {
  list-style-type: none !important;
  padding: 0;
}
</style>
